import * as fs from 'fs-web'

function readFile(file) {
    let fileContent = fs.readFile(file, 'utf-8')
    return fileContent
}

const styleHeaderExcel = {
    h1: { 
        font: { name:'Calibri', sz:12, bold:true, color:{ rgb: "000000" } }, 
        fill: { patternType:'solid', fgColor:{ rgb: "FFa6a2a2" }, bgColor:{indexed: '64'}},
        border: { 
          top:{ style:'thin', color:{ rgb: "000000" } },
          bottom:{ style:'thin', color:{ rgb: "000000" } },
          left:{ style:'thin', color:{ rgb: "000000" } },
          right:{ style:'thin', color:{ rgb: "000000" } },
        }
      },
    h2: { 
        font: { name:'Calibri', sz:12, bold:true, color:{ rgb: "000000" } }, 
        fill: { patternType:'solid', fgColor:{ rgb: "FF475ae6" }, bgColor:{indexed: '64'} },
        border: { 
          top:{ style:'thin', color:{ rgb: "000000" } },
          bottom:{ style:'thin', color:{ rgb: "000000" } },
          left:{ style:'thin', color:{ rgb: "000000" } },
          right:{ style:'thin', color:{ rgb: "000000" } },
        }
      },
    h3: { 
        font: { name:'Calibri', sz:12, bold:true, color:{ rgb: "000000" } }, 
        fill: { patternType:'solid', fgColor:{ rgb: "FF7cc76d" }, bgColor:{indexed: '64'}},
        border: { 
          top:{ style:'thin', color:{ rgb: "000000" } },
          bottom:{ style:'thin', color:{ rgb: "000000" } },
          left:{ style:'thin', color:{ rgb: "000000" } },
          right:{ style:'thin', color:{ rgb: "000000" } },
        }
      },
    h4: { 
        font: { name:'Calibri', sz:12, bold:true, color:{ rgb: "000000" } }, 
        fill: { patternType:'solid', fgColor:{ rgb: "FFf1f507" }, bgColor:{indexed: '64'}},
        border: { 
          top:{ style:'thin', color:{ rgb: "000000" } },
          bottom:{ style:'thin', color:{ rgb: "000000" } },
          left:{ style:'thin', color:{ rgb: "000000" } },
          right:{ style:'thin', color:{ rgb: "000000" } },
        }
      },
    h5: { 
        font: { name:'Calibri', sz:12, bold:true, color:{ rgb: "000000" } }, 
        fill: { patternType:'solid', fgColor:{ rgb: "FFf09e1a" }, bgColor:{indexed: '64'}},
        border: { 
          top:{ style:'thin', color:{ rgb: "000000" } },
          bottom:{ style:'thin', color:{ rgb: "000000" } },
          left:{ style:'thin', color:{ rgb: "000000" } },
          right:{ style:'thin', color:{ rgb: "000000" } },
        }
      },
    h6: { 
        font: { name:'Calibri', sz:12, bold:true, color:{ rgb: "000000" } }, 
        fill: { patternType:'solid', fgColor:{ rgb: "FF12cc53" }, bgColor:{indexed: '64'}},
        border: { 
          top:{ style:'thin', color:{ rgb: "000000" } },
          bottom:{ style:'thin', color:{ rgb: "000000" } },
          left:{ style:'thin', color:{ rgb: "000000" } },
          right:{ style:'thin', color:{ rgb: "000000" } },
        }
      }
}

export {readFile, styleHeaderExcel}